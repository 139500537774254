@font-face {
    font-family: "MyCustomFont";
    src: url("../fonts/Cocogoose-Pro-Bold-trial.ttf") format("truetype");
  }


  @font-face {
    font-family: "SubtitleFont";
    src: url("../fonts/Cocogoose-Pro-Light-trial.ttf") format("truetype");
  }

  
  h1 {
    font-family: "MyCustomFont", sans-serif;
  }

  p{
    font-family: "SubtitleFont", sans-serif;
  }


  /* Estilos personalizados para el enlace de soporte */
.support-link {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #ff4b5c;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .support-link:hover {
    background-color: #ff1f3a;
    text-decoration: none;
  }

  