@font-face {
  font-family: "MyCustomFont";
  src: url("../fonts/Cocogoose-Pro-Light-trial.ttf") format("truetype");
}

@font-face {
  font-family: "MyCustomFont2";
  src: url("../fonts/Cocogoose-Pro-Bold-trial.ttf") format("truetype");
}

body {

  background: #f3f4f6; /* Fondo base en gris claro */
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  margin: 0;
}
h1{
  font-family: "MyCustomFont2", sans-serif;

}
p{
  font-family: "MyCustomFont", sans-serif;

}

.background-dots {
  position: fixed; /* Asegurarse de que esté en el fondo */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; /* Colocar detrás de otros elementos */
  overflow: hidden;
}

.dot {
  position: absolute;
  width: 7px; /* Tamaño más pequeño */
  height: 7px; /* Tamaño más pequeño */
  background-color: rgba(176, 176, 176, 0.5); /* Color más claro */
  border-radius: 50%;
  animation: move 10s infinite;
}

/* Animación para mover los puntos */
/* @keyframes move {
  0% { transform: translateY(0) translateX(0); }
  50% { transform: translateY(calc(100vh - 10px)) translateX(calc(100vw - 10px)); }
  100% { transform: translateY(0) translateX(0); }
} */

/* .centered-image {
  max-width: 40%;
  max-height: 100%;
} */

/* Asegurando que la imagen sea responsive */
/* @media (max-width: 768px) {
  .centered-image {
    max-width: 80%; 
  }
} */

/* Estilos adicionales para la sección de soporte */
.support-section {
  margin-top: 20px;
}

.support-section p {
  margin-bottom: 10px;
}

.btn-primary {
  background-color: #ff4b5c;
  border-color: #ff4b5c;
}

.btn-primary:hover {
  background-color: #ff1f3a;
  border-color: #ff1f3a;
}
